import "core-js/modules/es.array.push.js";
import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import { getEntitiesList, pluralEntity, gender } from "@/utils/enums";
import { plural } from "@/utils/numbers";
export default {
  mixins: [ModalMixin],
  props: {},
  data() {
    return {
      list: {
        id: "",
        entity: "",
        mp: ""
      },
      importResult: {
        added: 0,
        skipped: 0,
        failed: 0
      }
    };
  },
  methods: {
    onOpen({
      list,
      result
    }) {
      this.list = {
        ...list
      };
      this.importResult = {
        ...result
      };
    },
    plural(value) {
      return pluralEntity(this.list.entity, value);
    }
  },
  computed: {
    title() {
      return `Добавление ${getEntitiesList("manyGenitive", "lower")[this.list.entity]}`;
    },
    actions() {
      return [{
        name: "submit",
        text: "ОК",
        action: ({
          close
        }) => {
          close();
        }
      }];
    },
    text() {
      const result = [];
      if (this.importResult.added) {
        let entities = this.plural(this.importResult.added);
        result.push(plural(this.importResult.added, [`${entities} успешно ${gender(this.list.entity, ["добавлен", "добавлена", "добавлено"])}!`, `${entities} успешно добавлены!`]));
      }
      if (this.importResult.skipped) {
        let entities = this.plural(this.importResult.skipped);
        result.push(plural(this.importResult.skipped, [`${entities} ${gender(this.list.entity, ["был добавлен", "была добавлена", "было добавлено"])} ранее.`, `${entities} были добавлены ранее.`]));
      }
      if (this.importResult.failed) {
        let entities = this.plural(this.importResult.failed);
        result.push(plural(this.importResult.failed, [`${entities} не содержится в нашей базе или ${gender(this.list.entity, ["введен", "введена", "введено"])} с ошибками.`, `${entities} не содержится в нашей базе или введены с ошибками.`]));
      }
      return result;
    }
  },
  components: {
    Modal
  }
};