var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.isOpened ? _c('portal', {
    attrs: {
      "to": "__modals"
    }
  }, [_c('transition', {
    key: `t:${_vm.uid}`,
    attrs: {
      "name": "modals-fade",
      "appear": ""
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    key: _vm.uid,
    staticClass: "modal"
  }, [_c('div', {
    staticClass: "modal-bg",
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.close.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "modal-body",
    style: {
      width: `${_vm.width}px`
    }
  }, [_c('div', {
    staticClass: "modal-btn-close",
    on: {
      "click": _vm.close
    }
  }, [_c('svg', {
    attrs: {
      "width": "40",
      "height": "42",
      "viewBox": "0 0 40 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M10 30.5L20 20.5M20 20.5L10 10.5M20 20.5L30 10.5M20 20.5L30 30.5",
      "stroke": "white",
      "stroke-width": "4",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])]), _c('div', {
    ref: "content",
    staticClass: "modal-content"
  }, [_vm._t("default"), _c('focus')], 2)])])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };