var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": _vm.title,
      "actions": _vm.actions
    }
  }, _vm._l(_vm.text, function (line, i) {
    return _c('p', {
      key: i
    }, [_vm._v(" " + _vm._s(line) + " ")]);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };