import Modal, { ModalMixin } from "@/components/modals/layouts/Large.vue";
import Wrapper from "./Wrapper";
export default {
  mixins: [ModalMixin],
  data() {
    return {
      alert: null,
      title: null
    };
  },
  methods: {
    onOpen({
      alert,
      title
    }) {
      this.alert = alert;
      this.title = title;
    }
  },
  mounted() {},
  components: {
    Modal,
    Wrapper
  }
};