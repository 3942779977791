var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.$store.state.prerender || _vm.$store.state.user.user && !_vm.hasItemsLoaded ? _c('div') : !_vm.$store.state.user.user || !_vm.hasItems ? _c('no-list-ms', {
    attrs: {
      "small": _vm.small,
      "text": _vm.text
    }
  }) : !_vm.hasAccess ? _c('div', [_vm._t("default")], 2) : _c('div', [_vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };