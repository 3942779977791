import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
export default {
  mixins: [ModalMixin],
  data() {
    const initial_new_list = {
      id: "",
      title: "",
      description: ""
    };
    return {
      initial_new_list,
      new_list: {
        ...initial_new_list
      },
      loading: false,
      onComplete: null
    };
  },
  mounted() {},
  methods: {
    onOpen({
      item,
      onComplete
    }) {
      this.new_list = {
        ...item
      };
      this.onComplete = onComplete;
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        const result = await this.$store.dispatch("listM/updateListM", {
          id: this.new_list.id,
          title: this.new_list.title,
          description: this.new_list.description
        });
        this.loading = false;
        close();
        this.onComplete && this.onComplete(result);
        this.$emit("complete", result);
      } catch (e) {
        this.loading = false;
      }
    }
  },
  computed: {
    valid() {
      var _this$new_list$title;
      return (_this$new_list$title = this.new_list.title) === null || _this$new_list$title === void 0 ? void 0 : _this$new_list$title.length;
    },
    actions() {
      return ["cancel", {
        name: "submit",
        text: "Сохранить изменения",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid,
        loading: this.loading
      }];
    }
  },
  components: {
    Modal
  }
};