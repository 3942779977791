import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import ModalMixin from "./Modal.mixin";
import Focus from "@/components/Focus";
export { ModalMixin };
export default {
  components: {
    Focus
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    width: {
      default: 858
    },
    actions: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      isOpened: false,
      focusKey: 1
    };
  },
  computed: {
    uid() {
      return this._uid;
    },
    visible() {
      return this.$store.state.modals.length && this.$store.state.modals.findIndex(i => i.id === this.uid) === this.$store.state.modals.length - 1;
    }
  },
  methods: {
    async open({
      exclusive = false
    } = {}) {
      if (exclusive) {
        this.$store.state.modals.forEach(i => i.id !== this.uid && i.close({
          immediately: true
        }));
      }
      await this.$nextTick();
      this.$store.state.modals.push({
        id: this.uid,
        close: opts => {
          this.close(opts);
        }
      });
      this.isOpened = true;
      this.$emit("open");
      this.focusKey++;
    },
    close({
      immediately = false
    } = {}) {
      this.$emit("close");
      this.$set(this.$store.state, "modals", this.$store.state.modals.filter(i => i.id !== this.uid));
      if (immediately) {
        this.isOpened = false;
      } else {
        setTimeout(() => {
          // this.isOpened = false; @TODO fix RML
        }, 300);
      }
    }
  },
  beforeDestroy() {
    this.$set(this.$store.state, "modals", this.$store.state.modals.filter(i => i.id !== this.uid));
  }
};