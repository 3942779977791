var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "lists-page__none",
    class: {
      small: _vm.small
    }
  }, [!_vm.small ? _c('div', {
    staticClass: "lists-page__none-title"
  }, [_vm._v("Мои мониторинги")]) : _vm._e(), _vm.small ? _c('img', {
    staticClass: "loading__img",
    attrs: {
      "src": require('@/assets/images/wireframes/listm.png')
    }
  }) : _vm._e(), _vm.small ? _c('div', {
    staticClass: "lists-page__none-text",
    class: {
      small: _vm.small
    }
  }, [_vm._v(" " + _vm._s(this.text) + " ")]) : _c('div', {
    staticClass: "lists-page__none-text"
  }, [_vm._v(" В этом разделе вы можете настроить регулярный мониторинг и уведомления об изменениях товаров, брендов, категорий и продавцов. Например: изменение цены на товары, появление нового бренда в категории, новые товары определенного селлера и и другие события. ")]), _c('button', {
    staticClass: "btn-outline btn-width-auto",
    class: {
      'connect-btn': _vm.small
    },
    on: {
      "click": _vm.onCreateList
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/plus.svg`)
    }
  }), _vm._v(" Настроить мониторинг изменений ")]), _c('modal-add-listM', {
    ref: "modalAddListM",
    on: {
      "complete": _vm.onAdded
    }
  }), _c('modal-listm-import', {
    ref: "modalListmImport",
    on: {
      "complete": function ($event) {
        _vm.refreshKey++;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };