export default {
  props: {
    component: {
      type: String,
      default: "button"
    },
    variant: {
      type: String,
      default: "white",
      validate: v => ["blue", "white"].includes(v)
    },
    block: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  }
};