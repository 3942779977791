var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "modals-access-wrapper"
  }, [_c('div', {
    staticClass: "alert"
  }, [_vm._t("alert")], 2), _c('div', {
    staticClass: "content"
  }, [_c('img', {
    staticClass: "bg",
    attrs: {
      "src": require(`@/assets/images/modal-register/bg.png`),
      "srcset": `${require(`@/assets/images/modal-register/bg@2x.png`)} 2x`
    }
  }), _c('div', {
    staticClass: "title"
  }, [_vm._t("title")], 2), _c('div', {
    staticClass: "form"
  }, [_vm._t("default")], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };