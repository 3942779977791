var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('list-ms-wrapper', {
    attrs: {
      "hasItems": _vm.hasItems,
      "hasItemsLoaded": _vm.hasItemsLoaded
    }
  }, [_c('div', {
    staticClass: "lists-page"
  }, [_c('div', {
    staticClass: "lists-page__content"
  }, [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('button', {
          staticClass: "btn-outline btn-width-auto",
          on: {
            "click": _vm.onCreateList
          }
        }, [_c('img', {
          attrs: {
            "src": require(`@/assets/images/icons/plus.svg`)
          }
        }), _vm._v(" Новый мониторинг ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" Мои мониторинги ")]), _c('list-ms-table', {
    key: _vm.tableKey,
    staticClass: "mt32",
    attrs: {
      "hasItems": _vm.hasItems,
      "ListMsTotalRow": _vm.ListMsTotalRow
    },
    on: {
      "edit": _vm.onEdit,
      "remove": _vm.onRemove,
      "update:hasItems": function ($event) {
        _vm.hasItems = $event;
      },
      "update:has-items": function ($event) {
        _vm.hasItems = $event;
      },
      "update:ListMsTotalRow": function ($event) {
        _vm.ListMsTotalRow = $event;
      },
      "update:list-ms-total-row": function ($event) {
        _vm.ListMsTotalRow = $event;
      }
    }
  })], 1), _c('modal-add-listM', {
    ref: "modalAddListM",
    on: {
      "complete": _vm.onAdded
    }
  }), _c('modal-update-listM', {
    ref: "modalUpdateListM"
  }), _c('modal-remove-listM', {
    ref: "modalRemoveListM"
  }), _c('modal-listm-import', {
    ref: "modalListmImport",
    on: {
      "complete": function ($event) {
        _vm.refreshKey++;
      }
    }
  }), _c('modal-limit-reached-template', {
    ref: "ModalLimitReachedTemplate"
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };